<template>
  <div class="bg-white">
    <app-modal-confirm
      ref="entry_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteEntry()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="entry_modal_user_number"
      :modal-header-title="$t('customers.user_number_confirm_title')"
      @confirmed="handleLoadUserNumber()"
      cancelText="キャンセル"
      confirmTextText="OK"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("customers.user_number_confirm_body") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="modal_confirm_change_apartment"
      :modal-header-title="$t('customers.user_number_confirm_title')"
      cancelText="キャンセル"
      @cancel="entry.apartment_id = currentApartment"
      @confirmed="changeApartment()"
      @close="entry.apartment_id = currentApartment"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("customers.change_apartment_confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="modal_confirm_change_status_enabled"
      :modal-header-title="$t('customers.user_number_confirm_title')"
      cancelText="キャンセル"
      @cancel="cancelPopStatus()"
      @confirmed="changeApartment()"
      @close="cancelPopStatus()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("customers.change_status_confirm") }}</p>
      </template>
    </app-modal-confirm>


    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
        <div class="card card-body">
          <div class="form-group row" v-if="idEditing">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
              <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
          </div>
          <div class="form-group row" v-if="idEditing">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.line_id") }}</label>
              <div class="col-md-4 p-t-8"> {{ entry.line_id }} ({{ isLineLink }})</div>
<!--              <div class="col-sm-3 p-t-8"> ({{ entry.linked_at + (entry.line_id && entry.linked_at) ? '登録済' : '未登録'}})</div>-->
          </div>
          <div class="form-group row" v-if="idEditing">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.line_code") }} <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <div class="d-flex">
                <app-input
                  :disabled="true"
                  name="line_code"
                  type="string:64"
                  rules="required"
                  v-model="entry.line_code"
                  :class="['flex-fill']"
                />
                <button class="btn btn-success m-l-10" style="height: 40px !important;" type="button" @click="generateLineCode">
                  {{  $t('common.generate') }}
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row" v-if="idEditing">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <p class="notifi">※再生成ボタンを押すとLINE登録用のパスコードが乱数で新しく生成されます。</p>
              <p class="notifi">※LINE ID を変更する場合は新しく生成した登録用のパスコードを使って再度登録を行ってください。</p>
            </div>
          </div>

          <validation-provider
            name="status_enabled"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.status_enabled") }} <span class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-6 align-self-center">
                <app-radio :label="$t('common.active')" val="1" v-model="entry.status_enabled"/>
                <app-radio :label="$t('common.deactive')" isMarginLeft="true" val="0" v-model="entry.status_enabled"/>
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.apartment") }} <span class="app-require">＊</span></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-select
                name="apartment_id"
                rules="required"
                :options-data="meta.apartments"
                v-model="entry.apartment_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.name_last") }} <span class="app-require required-label">＊</span>
            </label>
            <div class="col-xl-2 col-lg-2 col-sm-2">
              <app-input
                name="name_last"
                type="string:85"
                rules="required"
                v-model="entry.name_last"
              />
            </div>

            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label" style="flex: 0 0 8.5%;">{{ $t("common.name_first") }} <span class="app-require required-label">＊</span>
            </label>
            <div class="col-xl-2 col-lg-2 col-sm-2">
              <app-input
                name="name_first"
                type="string:85"
                rules="required"
                v-model="entry.name_first"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.kana_last") }}
            </label>
            <div class="col-xl-2 col-lg-2 col-sm-2">
              <app-input
                name="kana_last"
                type="string:85"
                rules="katakana"
                v-model="entry.kana_last"
              />
            </div>
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label" style="flex: 0 0 8.5%;">{{ $t("common.kana_first") }}
            </label>
            <div class="col-xl-2 col-lg-2 col-sm-2">
              <app-input
                name="kana_first"
                type="string:85"
                rules="katakana"
                v-model="entry.kana_first"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.email") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="email"
                type="string128"
                rules="email"
                v-model="entry.email"
              />
            </div>
          </div>
          <div class="form-group row" v-if="!idEditing">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.line_code") }} <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                :disabled="true"
                name="line_code"
                type="string:64"
                rules="required"
                v-model="entry.line_code"
              />
            </div>
          </div>
          <div class="form-group row" v-if="!idEditing">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <p class="notifi">LINE登録用のパスコードは乱数で生成されます。</p>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.phone") }} <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="phone_number"
                type="phone"
                rules="required|phone_number"
                v-model="entry.phone_number"
                placeholder="00000000000"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.phone_cellular") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="phone_cellular"
                type="phone"
                rules="phone_number"
                v-model="entry.phone_cellular"
                placeholder="00000000000"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.fax") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="phone_fax"
                type="phone"
                rules="phone_number"
                v-model="entry.phone_fax"
                placeholder="00000000000"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.room") }} <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="room_no"
                type="number-length:6"
                v-model="entry.room_no"
                rules="required"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.birthday") }} <span class="app-require">＊</span>
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-date-range-picker
                name="birthday"
                v-model="entry.birthday"
                rules="required"
                format="YYYY-MM-DD"
                timePicker24Hour="false"
              />
            </div>
            <div class="mt-2" style="font-weight: bold !important;color: red; text-align: right">
              ※正しい生年月日を入力してください。
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.blood_type") }}
            </label>
            <div class="col-xl-2 col-lg-2 col-sm-2">
              <app-select
                name="blood_type"
                :options-data="meta.blood_types"
                v-model="entry.blood_type"
              />
            </div>
            <label class="col-xl-1 col-lg-2 col-sm-2 title-rh">{{ $t("customers.blood_rh")}}
            </label>
            <div class="col-xl-2 col-lg-2 col-sm-2">
              <app-select
                name="blood_rh"
                rules=""
                :options-data="meta.blood_rhs"
                v-model="entry.blood_rh"
              />
            </div>
          </div>
          <validation-provider
            name="gender"
            rules="required"
            v-slot="{ errors, invalid, validated }"
          >
            <div class="form-group row mb-0">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label py-0">{{ $t("customers.gender") }} <span class="app-require">＊</span>
              </label>
              <div class="col-xl-5 col-lg-6 col-sm-6 align-self-center">
                <app-radio v-for="(item, index) in meta.gender" :isMarginLeft="index != 1 ? true:false" :key="index + item" :label="item" :val="index" v-model="entry.gender_code"/>
              </div>
            </div>
            <div class="row">
              <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label"></label>
              <div class="col-xl-5 col-lg-6 col-sm-6">
                <label v-if="invalid && validated" class="error">
                  {{ errors[0] }}
                </label>
              </div>
            </div>
          </validation-provider>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.setai") }}
            </label>
            <div class="col-xl-6 col-lg-7 col-sm-9 align-self-center">
              <app-radio v-for="(item, index) in meta.setais" :isMarginLeft="index != 1 ? true:false" :key="index + item" :label="item" :val="index" v-model="entry.setai_code" />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.note") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-text
                name="note"
                type="string1024"
                v-model="entry.note"
              />
            </div>
          </div>
          <br>
          <h4 class="heading-title">ほのぼの会計 連係情報</h4>
<!--          <div class="form-group row">-->
<!--            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("customers.user_number") }} <span class="app-require">＊</span>-->
<!--            </label>-->
<!--            <div class="col-xl-5 col-lg-6 col-sm-6">-->
<!--              <div class="d-flex">-->
<!--                <app-input-->
<!--                  name="user_number"-->
<!--                  type="number-length:10"-->
<!--                  rules="required|max_value:4294967295"-->
<!--                  v-model="entry.user_number"-->
<!--                  :class="['flex-fill']"-->
<!--                />-->
<!--                <button class="btn btn-success m-l-10" style="height: 40px !important;" type="button" @click="handleSyncData(entry.user_number)">-->
<!--                  {{  $t('customers.sync_data') }}-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.user_number") }} <span class="app-require">＊</span></label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="user_number"
                type="number-length:10"
                rules="required|max_value:4294967295"
                v-model="entry.user_number"
              />
            </div>
          </div>
          <br>
          <h4 class="heading-title">緊急連絡先</h4>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.phone") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="contact_phone"
                type="phone"
                rules="phone_number"
                v-model="entry.contact_phone"
                placeholder="00000000000"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("customers.phone_cellular") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="contact_cellular"
                type="phone"
                rules="phone_number"
                v-model="entry.contact_cellular"
                placeholder="00000000000"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.postal_code") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="contact_postal_code"
                type="ja_postal_code"
                v-model="entry.contact_postal_code"
                placeholder="0000000"
              />
            </div>
          </div>
          <div class="form-group row" style="display: none">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("apartments.prefecture") }} <span class="app-require">＊</span></label>
            <div class="col-sm-6">
              <app-select
                name="prefecture"
                :options-data="meta.prefectures"
                v-model="entry.pref_id"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.address") }}
            </label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-input
                name="contact_address"
                type="string128"
                v-model="entry.contact_address"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.note") }}</label>
            <div class="col-xl-5 col-lg-6 col-sm-6">
              <app-text
                name="contact_note"
                type="string1024"
                v-model="entry.contact_note"
              />
            </div>
          </div>
          <div class="card-footer row justify-content-end gr-button">
            <button type="button" :disabled="(!valid && validated) || !validLineCode" class="btn btn-default m-r-10" @click="confirmCancel">
              {{ $t('common.cancel') }}
            </button>
            <button v-if="idEditing" :disabled="(!valid && validated) || !validLineCode" class="btn btn-primary" type="submit">
              {{  $t('common.save') }}
            </button>
            <button v-else :disabled="(!valid && validated) || !validLineCode" class="btn btn-primary" type="submit">
              {{  $t('common.create') }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {createHmac} from "crypto";
import {__noticeWarning} from "../../../utils";
import Hashids from 'hashids'
import {ROUTES} from "@constants";
export default {
  name: "FormPage",

  components: {
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        // gender_code: null,
        // setai: null,
        status_enabled: 1,
      },
      entryByUserNumber: {},
      apiKeyId: null,
      meta: {
        apartments: [],
        blood_types: [],
        blood_rhs: [],
        gender: [],
        setais: [],
        status: [],
      },
      currentApartment : '',
      currentStatus : '',
      idEditing: id,
      isLineLink: '',
      validLineCode : false
    };
  },

  watch: {
    'entry.contact_postal_code': function(value, oldValue) {
      if (oldValue == undefined || value == undefined) {
        return;
      }
      if (value != oldValue && value.length == 7 ) {
        this.getByPostalCode(value)
      }
    },
    "entry.apartment_id" : function (value) {
      if (this.idEditing  && value != this.currentApartment && this.entry.line_id) {
          this.confirmChangeApartment();
      }
    },
    "entry.status_enabled" : function (value) {
      if (this.idEditing && value != this.currentStatus && this.entry.line_id) {
        this.confirmChangeStatus();
      }
    }
  },

  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER).then(res => {
      let blood_types = _.cloneDeep(res.data.blood_types);
      blood_types.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let blood_rhs = _.cloneDeep(res.data.blood_rhs);
      blood_rhs.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let prefectures = _.cloneDeep(res.data.prefectures);
      prefectures.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      let apartments = _.cloneDeep(res.data.apartments);
      apartments.unshift({
        id: null,
        name: this.$t('common.choose'),
      });
      this.meta = {
        apartments: apartments,
        blood_types: blood_types,
        blood_rhs: blood_rhs,
        gender: res.data.gender,
        setais: res.data.setais,
        status: res.data.status,
        prefectures: prefectures,
      }
    })
    if (this.idEditing) {
      this.$request.get(this.ENDPOINT.CUSTOMER_EDIT(this.idEditing)).then(res => {
        if (res.hasErrors()) {
          if (res.status === 'error'){
            this.__noticeError(this.$t(res.data.error_msg));
          }
          this.redirectDefaultValue()
        } else {
          this.entry = res.data.data
          this.currentApartment = this.entry.apartment_id;
          this.currentStatus = this.entry.status_enabled
          this.isLineLink = (this.entry.line_id && this.entry.linked_at) ? '登録済' : '未登録'
        }

      })
    }
    if (this.entry.line_code == undefined) {
      this.generateLineCode();
    }

  },

  methods: {
    confirmChangeApartment() {
      this.$refs.modal_confirm_change_apartment.show();
    },
    cancelPopStatus() {
      this.entry.status_enabled = this.currentStatus;
    },
    confirmChangeStatus() {
      this.$refs.modal_confirm_change_status_enabled.show();
    },
    changeApartment() {
      this.$refs.modal_confirm_change_apartment.hide();
      this.$refs.modal_confirm_change_status_enabled.hide();
    },
    confirmCancel() {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.CUSTOMER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() {
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.CUSTOMER_EDIT(this.idEditing), params);
        msg = this.$t("customers.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.CUSTOMER_CREATE, params);
        msg = this.$t("customers.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        if (res.status === 'error') {
          await this.redirectDefaultValue()
        }
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue()
      }
    },
    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.CUSTOMER_LIST, query: {
          'filters.status_enabled.equal': 'all',
          'filters.apartment_id.equal': 'all',
        }
      });
    },
    handleDeleteEntry() {
      this.$refs.entry_modal_delete.show();
    },
    async deleteEntry() {
      const res = await this.$request.delete(this.ENDPOINT.APARTMENT_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg create ok"));
        await this.redirectDefaultValue()
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.entry_modal_delete.hide();
        if (res.status === 'error') {
          await this.redirectDefaultValue();
        }
      }
    },
    async generateLineCode() {
        const secret = Math.random().toString(36).substring(2, 22)
        const hashids = new Hashids(secret, 16)
        this.entry.line_code = hashids.encode(Date.now());
        let count = 0;
        do {
          this.validLineCode = await this.checkExistLineCode(this.entry.line_code)
          count ++;
        }while (!this.validLineCode && count < 1000);
        if (count >=1000) {
          this.__noticeError("コード生成を最大数試行しましたが重複エラーが発生しました。時間をおいて再度試してください。");
        }
    },
    async checkExistLineCode(code) {
     let result = await this.$request.post(this.ENDPOINT.CUSTOMER_CHECK_LINE_CODE, {
        code : code,
        id : this.idEditing ? this.idEditing : null
      });

      return result.data.data;
    },
    getByPostalCode(postalCode) {
      this.$request.get(this.ENDPOINT.ADDRESS_BY_ZIPCODE(postalCode)).then(res => {
        var data = res.data;
        if (data.province_id != undefined) {
          if (data.area == '以下に掲載がない場合') {
            data.area = '';
          }
          this.entry.contact_address = data.city + data.area;
          this.entry.pref_id = data.province_id;
        } else {
          this.__noticeError(this.$t('messages.postal_code_invalid'));
        }
      })
    },

    handleSyncData() {
      this.$refs.entry_modal_user_number.show()
    },

    handleLoadUserNumber() {
      this.$request.get(this.ENDPOINT.CUSTOMER_USER_NUMBER(this.entry.user_number)).then(res => {
        let data = res.data.data;
        if (data != undefined) {
          let lineId = this.entry.line_id;
          let lineCode = this.entry.line_code;
          let status = this.entry.status_enabled;
          let roomNo = this.entry.room_no;
          let linkedAt = this.entry.linked_at;
          this.entry = data;
          this.entry.name = data.fullname;
          this.entry.name_kana = data.fullname_kana;
          this.entry.line_id = lineId;
          this.entry.line_code = lineCode;
          this.entry.status_enabled = status;
          this.entry.room_no = roomNo;
          this.entry.linked_at = linkedAt;

          this.$refs.entry_modal_user_number.hide();
        } else {
          this.__noticeWarning(this.$t('customers.user_number_customer_not_found'));
          this.$refs.entry_modal_user_number.hide();
        }
      })
    },
  },
};
</script>

<style>
.required-label {
  position: relative;
  left: -10px;
}
</style>
